import Tracker from '@/util/tracker.js'

export function adblockDetected() {
  return document.getElementById('egbsUvWwVHOf') == null
}

window.addEventListener('load', () => {
  if (!adblockDetected()) {
    return
  }

  Tracker.track('adblock detected')
})

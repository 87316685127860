// Load anything used in most or all pages here
import '@/styles/application.css'

import '@/util/init.js'
import '@/util/sentry.js'
import '@/util/adblock.js'
// import '@/util/stripe.js'
import '@/util/floating-labels.js'
// import { reloadOnNetworkErrors } from '@/util/network-error-reload'

if (RAILS_ENV !== 'production') {
  console.log('environment', RAILS_ENV)
}

// reloadOnNetworkErrors()
